// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Spacing
// ---------------------------------------------------------------------------
// Extend bootstraps spacing map to contain additional spcaing options
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@use "sass:math";
@import 'variables';

$spacer: 1rem;

@function calculate-space($amount) {
  @return #{math.div($amount, $font-size-default)}rem;
}

$spacers: (
  0: 0
);

//        1   2    3    4    5    6    7    8    9    10   11   12
$spaces: 5px 10px 15px 20px 30px 40px 50px 60px 70px 80px 90px 100px;

@for $i from 1 through length($spaces) {
  $item: nth($spaces, $i);
  $current-space: ( $i: calculate-space($item) );
  $spacers: map-merge($current-space, $spacers);
}
